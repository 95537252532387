import { EventEmitter, Injectable, OnDestroy } from '@angular/core';
import { NotificationServiceProxy } from '@shared/service-proxies/service-proxies';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, pluck, shareReplay } from 'rxjs/operators';
import { LayoutConfig } from './layout-config';

@Injectable()
export class LayoutStoreService implements OnDestroy {
    private subscription: Subscription = new Subscription();

    public readonly config$: Observable<LayoutConfig>;

    public notificationsValue = 0;
    public updateNotificationNumber: BehaviorSubject<number> = new BehaviorSubject<number>(0);

    private readonly initialLayoutConfig: LayoutConfig = {
        sidebarExpanded: false,
    };
    private configSource: BehaviorSubject<LayoutConfig>;

    constructor(private notificationService: NotificationServiceProxy) {
        this.configSource = new BehaviorSubject(this.initialLayoutConfig);
        this.config$ = this.configSource.asObservable();
    }

    get sidebarExpanded(): Observable<boolean> {
        return this.config$.pipe(pluck('sidebarExpanded'), distinctUntilChanged()) as Observable<boolean>;
    }

    public setSidebarExpanded(value: boolean): void {
        this.configSource.next(Object.assign(this.configSource.value, { sidebarExpanded: value }));
    }

    public updateNotificationValue() {
        const sub = this.notificationService.getUnreadNotificationsCount().subscribe((number) => {
            this.notificationsValue = number;
            this.updateNotificationNumber.next(this.notificationsValue);
        });
        this.subscription.add(sub);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
