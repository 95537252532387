import { Injectable } from '@angular/core';
import { FeatureCheckerService, PermissionCheckerService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/session/app-session.service';

import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { PermissionConsts } from '@shared/PermissionConsts';
import { FeatureConsts } from '@shared/FeatureConsts';

@Injectable()
export class AppRouteGuard implements CanActivate, CanActivateChild {
    constructor(
        private _permissionChecker: PermissionCheckerService,
        private _router: Router,
        private _sessionService: AppSessionService,
        private featureCheckerService: FeatureCheckerService
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        let canActivate = true;
        if (!this._sessionService.user) {
            this._router.navigate(['/account/login']);
            return false;
        }
        if (!route.data || (!route.data['permission'] && !route.data['feature'])) {
            return true;
        }

        if (route.data['permission']) canActivate = this._permissionChecker.isGranted(route.data['permission']);
        if (route.data['feature']) canActivate = canActivate && this.featureCheckerService.isEnabled(route.data['feature']);
        if (!canActivate) this._router.navigate([this.selectBestRoute()]);
        return canActivate;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    selectBestRoute(): string {
        if (this._permissionChecker.isGranted(PermissionConsts.User_IsAssignableInServiceOperator)) {
            return '/app/user/myProfile';
        }
        if (this._permissionChecker.isGranted(PermissionConsts.Pages_Contracts) && this.featureCheckerService.isEnabled(FeatureConsts.Contracts)) {
            return '/app/contract';
        }
        if (this._permissionChecker.isGranted(PermissionConsts.Pages_Services) && this.featureCheckerService.isEnabled(FeatureConsts.Services)) {
            return '/app/service';
        }
        if (this._permissionChecker.isGranted(PermissionConsts.Pages_Users)) {
            return '/app/user';
        }
        return '/app';
    }
}
