import { Pipe, PipeTransform } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';

@Pipe({
    name: 'formErrors',
})
export class FormErrorsPipe implements PipeTransform {
    transform(form: FormGroup): any {
        let errors: any = {};
        for (let controlsKey in form.controls) {
            if (form.controls[controlsKey] instanceof FormControl) {
                if (form.controls[controlsKey].errors) {
                    errors[controlsKey] = form.controls[controlsKey].errors;
                }
            } else if (form.controls[controlsKey] instanceof FormGroup) {
                const group: FormGroup = form.controls[controlsKey] as FormGroup;
                errors[controlsKey] = this.transform(group);
            } else if (form.controls[controlsKey] instanceof FormArray) {
                const array: FormArray = form.controls[controlsKey] as FormArray;
                if (array?.controls?.length) {
                    for (let arrayKey = 0; arrayKey < array.controls.length; arrayKey++) {
                        const group: FormGroup = array.controls[arrayKey] as FormGroup;
                        if (!errors[controlsKey]) {
                            errors[controlsKey] = [];
                        }
                        errors[controlsKey][arrayKey] = this.transform(group);
                    }
                }
            }
        }
        return errors;
    }
}
