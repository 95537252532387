import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpHttpInterceptor } from 'abp-ng2-module';

import * as ApiServiceProxies from './service-proxies';
import { LoaderInterceptor } from '@shared/interceptors/loader.interceptor';

@NgModule({
    providers: [
        ApiServiceProxies.RoleServiceProxy,
        ApiServiceProxies.SessionServiceProxy,
        ApiServiceProxies.TenantServiceProxy,
        ApiServiceProxies.UserServiceProxy,
        ApiServiceProxies.TokenAuthServiceProxy,
        ApiServiceProxies.AccountServiceProxy,
        ApiServiceProxies.ConfigurationServiceProxy,
        ApiServiceProxies.MunicipalityServiceProxy,
        ApiServiceProxies.CertificationServiceProxy,
        ApiServiceProxies.UserCertificationServiceProxy,
        ApiServiceProxies.EducationalDegreeServiceProxy,
        ApiServiceProxies.QualificationServiceProxy,
        ApiServiceProxies.ProfessionalRegisterServiceProxy,
        ApiServiceProxies.ContractServiceProxy,
        ApiServiceProxies.CustomerServiceProxy,
        ApiServiceProxies.UserProfessionalRegisterServiceProxy,
        ApiServiceProxies.UserEducationalDegreeServiceProxy,
        ApiServiceProxies.UserQualificationServiceProxy,
        ApiServiceProxies.ServiceServiceProxy,
        ApiServiceProxies.ServiceOperatorServiceProxy,
        ApiServiceProxies.ServiceBeneficiaryServiceProxy,
        ApiServiceProxies.FileServiceProxy,
        ApiServiceProxies.AreaServiceProxy,
        ApiServiceProxies.ServiceAreaServiceProxy,
        ApiServiceProxies.ScheduledNotificationServiceProxy,
        ApiServiceProxies.FileTypeServiceProxy,
        ApiServiceProxies.WorkContractServiceProxy,
        ApiServiceProxies.UserFileServiceProxy,
        ApiServiceProxies.ContractFileServiceProxy,
        ApiServiceProxies.ServiceFileServiceProxy,
        ApiServiceProxies.ScheduledNotificationUserServiceProxy,
        ApiServiceProxies.NotificationServiceProxy,
        ApiServiceProxies.ServiceOperatorScheduledServiceProxy,
        ApiServiceProxies.OrganizationUnitServiceProxy,
        ApiServiceProxies.HRRequestServiceProxy,
        ApiServiceProxies.UserOrganizationUnitServiceProxy,
        ApiServiceProxies.HRRequestTypeOrganizationUnitsServiceProxy,
        ApiServiceProxies.HRRequestOrganizationUnitsApproverServiceProxy,
        ApiServiceProxies.TopicServiceProxy,
        ApiServiceProxies.ApprovalLevelCarbonCopyServiceProxy,
        ApiServiceProxies.MessageServiceProxy,
        ApiServiceProxies.ServiceOperatorBeneficiaryServiceProxy,
        ApiServiceProxies.ServiceCustomerServiceProxy,
        ApiServiceProxies.ServiceReportServiceProxy,
        ApiServiceProxies.UserReportPartialServiceProxy,
        ApiServiceProxies.EducationalDegreeLevelServiceProxy,
        ApiServiceProxies.DuePaycheckServiceProxy,
        ApiServiceProxies.DuePaycheckFileServiceProxy,
        ApiServiceProxies.ContractTypeDictionaryServiceProxy,
        ApiServiceProxies.ActivityServiceProxy,
        ApiServiceProxies.CommitmentServiceProxy,
        ApiServiceProxies.CategoryDictionaryServiceProxy,
        ApiServiceProxies.ReceiptServiceProxy,
        ApiServiceProxies.ReceiptFilesServiceProxy,
        ApiServiceProxies.SummaryTrackingServiceProxy,
        ApiServiceProxies.WorkContractLevelDictionaryServiceProxy,
        { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    ],
})
export class ServiceProxyModule {}
