import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormGroup } from '@angular/forms';
import { AccountServiceProxy, ServiceOperatorServiceProxy, ServiceReportServiceProxy } from '@shared/service-proxies/service-proxies';
import { EMPTY, Observable, of, timer } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AsyncValidators {
    constructor(
        private serviceOperatorService: ServiceOperatorServiceProxy,
        private accountService: AccountServiceProxy,
        private serviceReportService: ServiceReportServiceProxy
    ) {}

    public operatorValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            const formValue = (control as FormGroup).getRawValue();
            if (formValue.userId && formValue.serviceId) {
                return this.serviceOperatorService.getAll(formValue.userId, formValue.serviceId, undefined, undefined, undefined, undefined).pipe(
                    map((res) => {
                        return res.items.length == 0 ? { operatorExists: true } : null;
                    })
                );
            } else return EMPTY;
        };
    }

    public serviceReportValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            const formValue = (control as FormGroup).getRawValue();
            const serviceId = formValue.serviceId;
            const year = formValue.year;
            const quarter = formValue.quarter;
            if (serviceId && year && quarter)
                return timer(800).pipe(
                    switchMap(() => this.serviceReportService.checkIfServiceReportExist(serviceId, year, quarter)),
                    map((res) => {
                        return res ? { duplicatedServiceReport: true } : null;
                    })
                );
            else return of(null);
        };
    }

    public duplicatedEmailValidator(): AsyncValidatorFn {
        return (control: AbstractControl): Observable<{ [key: string]: any } | null> => {
            const formUserId = control.parent.getRawValue().id;
            return this.accountService.userNameOrEmailExist(control.value, formUserId ? formUserId : undefined).pipe(
                map((res) => {
                    if (res.result) return null;
                    else {
                        if (res.message == 'IdentityDuplicateEmailDeleted') return { duplicatedDeletedEmail: true };
                        else if (res.message == 'IdentityDuplicateEmail') {
                            return { duplicatedEmail: true };
                        } else return { duplicatedEmail: true };
                    }
                })
            );
        };
    }
}
